<template>
    <a-card>
        <a-tabs v-model="activeTab">
            <a-tab-pane key="1" tab="巨量广告"></a-tab-pane>
        </a-tabs>

        <div class="content">
            <a-row>
                <a-col :span="12">
                    <div class="rate">{{ utils.disposeDecimal(dataInfo.carSeriesTemplateCoverage, 2, true) }}%</div>
                    <div class="txt">截止昨日（在售、即将在售）车系数{{ dataInfo.yesterdayCarSeriesNum || 0 }}</div>
                    <div class="title">广告主（在售、即将销售）车系模板覆盖率</div>
                </a-col>
                <a-col :span="12">
                    <div class="rate">{{ utils.disposeDecimal(dataInfo.principalTemplateCoverage, 2, true) }}%</div>
                    <div class="txt">截止昨日品牌数{{ dataInfo.yesterdayBrandNum || 0 }}</div>
                    <div class="title">广告主品牌模板覆盖率</div>
                </a-col>
            </a-row>
        </div>

        <a-form-model
            ref="form"
            :model="form"
            v-bind='layout'
        >
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="品牌">
                        <a-select
                            mode="multiple"
                            v-model="form.principalIdList"
                            placeholder="请选择品牌"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option
                                v-for="item in principalList"
                                :key="item.id"
                            >
                                {{ item.principal_name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                    <a-form-model-item label="销售状态">
                        <a-select
                            mode="multiple"
                            v-model="form.saleStatusList"
                            placeholder="请选择"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option v-for="item in stateList" :key="item.id">{{ item.label }}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col class="flex-end">
                    <a-space>
                        <a-button @click="handleReset">重置</a-button>
                        <a-button type="primary" @click="handleSearch">查询</a-button>
                    </a-space>
                </a-col>
            </a-row>
        </a-form-model>

        <a-table
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            :rowKey="record => record.id"
            :pagination='false'
            :scroll="{x: true}"
            bordered
            class="mt-20"
        >
        </a-table>

        <base-pagination
            :currentPage="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            @onChange="handlePaginationChange"
            @onShowSizeChange="handlePaginationChange"
        />
        <div class="mt-20">PS：数据每天凌晨更新1次</div>
    </a-card>
</template>

<script>
import utils from "@/utils/FormatUtil.js";

const stateList = [
    { id: 1, label: '在售' },
    { id: 2, label: '停售' },
    { id: 3, label: '即将销售' },
    { id: 4, label: '未售' },
]
export default {
    data() {
        return {
            utils,
            activeTab: '1',
            dataInfo: {},
            layout: {
                labelCol: { span: 8 },
                wrapperCol: { span: 16 }
            },
            form: {
                principalIdList: [],
                saleStatusList: []
            },
            loading: false,
            columns: [
                {
                    align: 'center',
                    title: '品牌',
                    dataIndex: 'principalName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '车系',
                    dataIndex: 'carSeriesName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '销售状态',
                    dataIndex: 'saleStatusName',
                    customRender: (text, row) => {
                        let item = stateList.filter(n => n.id == row.saleStatus)[0]
                        return item ? item.label : '-'
                    }
                },
                {
                    align: 'center',
                    title: '完成场景数',
                    // dataIndex: 'scenesCompletedNum',
                    customRender: (text, row) => {
                        return (row.scenesCompletedNum ?? '') !== '' ? row.scenesCompletedNum : '-'
                    }
                },
                {
                    align: 'center',
                    title: '获取底价场景',
                    children: [
                        {
                            title: '橙子建站模板',
                            dataIndex: ['basePriceTemplateVO', 'landingPageTemplateIsCovered'],
                            key: 'basePriceTemplateVO.landingPageTemplateIsCovered',
                            width: 80,
                            customRender: (text, row) => {
                                let txt1 = <div class="box red">无</div>
                                let txt2 = <div class="box green">有</div>
                                return text == 1 ? txt2 : txt1
                            }
                        },
                        {
                            title: '巨量创意组件模板',
                            dataIndex: ['basePriceTemplateVO', 'creativeComponentTemplateIsCovered'],
                            key: 'basePriceTemplateVO.creativeComponentTemplateIsCovered',
                            width: 90,
                            customRender: (text, row) => {
                                let txt1 = <div class="box red">无</div>
                                let txt2 = <div class="box green">有</div>
                                return text == 1 ? txt2 : txt1
                            }
                        },
                        {
                            title: '创意标题模板',
                            dataIndex: ['basePriceTemplateVO', 'creativeTitleTemplateIsCovered'],
                            key: 'basePriceTemplateVO.creativeTitleTemplateIsCovered',
                            width: 80,
                            customRender: (text, row) => {
                                let txt1 = <div class="box red">无</div>
                                let txt2 = <div class="box green">有</div>
                                return text == 1 ? txt2 : txt1
                            }
                        },
                        {
                            title: '创意标签模板',
                            dataIndex: ['basePriceTemplateVO', 'creativeLabelTemplateIsCovered'],
                            key: 'basePriceTemplateVO.creativeLabelTemplateIsCovered',
                            width: 80,
                            customRender: (text, row) => {
                                let txt1 = <div class="box red">无</div>
                                let txt2 = <div class="box green">有</div>
                                return text == 1 ? txt2 : txt1
                            }
                        },
                    ]
                },
                {
                    align: 'center',
                    title: '预约试驾场景',
                    children: [
                        {
                            title: '橙子建站模板',
                            dataIndex: ['testDriveTemplateVO', 'landingPageTemplateIsCovered'],
                            key: 'testDriveTemplateVO.landingPageTemplateIsCovered',
                            width: 80,
                            customRender: (text, row) => {
                                let txt1 = <div class="box red">无</div>
                                let txt2 = <div class="box green">有</div>
                                return text == 1 ? txt2 : txt1
                            }
                        },
                        {
                            title: '巨量创意组件模板',
                            dataIndex: ['testDriveTemplateVO', 'creativeComponentTemplateIsCovered'],
                            key: 'testDriveTemplateVO.creativeComponentTemplateIsCovered',
                            width: 90,
                            customRender: (text, row) => {
                                let txt1 = <div class="box red">无</div>
                                let txt2 = <div class="box green">有</div>
                                return text == 1 ? txt2 : txt1
                            }
                        },
                        {
                            title: '创意标题模板',
                            dataIndex: ['testDriveTemplateVO', 'creativeTitleTemplateIsCovered'],
                            key: 'testDriveTemplateVO.creativeTitleTemplateIsCovered',
                            width: 80,
                            customRender: (text, row) => {
                                let txt1 = <div class="box red">无</div>
                                let txt2 = <div class="box green">有</div>
                                return text == 1 ? txt2 : txt1
                            }
                        },
                        {
                            title: '创意标签模板',
                            dataIndex: ['testDriveTemplateVO', 'creativeLabelTemplateIsCovered'],
                            key: 'testDriveTemplateVO.creativeLabelTemplateIsCovered',
                            width: 80,
                            customRender: (text, row) => {
                                let txt1 = <div class="box red">无</div>
                                let txt2 = <div class="box green">有</div>
                                return text == 1 ? txt2 : txt1
                            }
                        },
                    ]
                },
                {
                    align: 'center',
                    title: '优惠政策场景',
                    children: [
                        {
                            title: '橙子建站模板',
                            dataIndex: ['preferentialTemplateVO', 'landingPageTemplateIsCovered'],
                            key: 'preferentialTemplateVO.landingPageTemplateIsCovered',
                            width: 80,
                            customRender: (text, row) => {
                                let txt1 = <div class="box red">无</div>
                                let txt2 = <div class="box green">有</div>
                                return text == 1 ? txt2 : txt1
                            }
                        },
                        {
                            title: '巨量创意组件模板',
                            dataIndex: ['preferentialTemplateVO', 'creativeComponentTemplateIsCovered'],
                            key: 'preferentialTemplateVO.creativeComponentTemplateIsCovered',
                            width: 90,
                            customRender: (text, row) => {
                                let txt1 = <div class="box red">无</div>
                                let txt2 = <div class="box green">有</div>
                                return text == 1 ? txt2 : txt1
                            }
                        },
                        {
                            title: '创意标题模板',
                            dataIndex: ['preferentialTemplateVO', 'creativeTitleTemplateIsCovered'],
                            key: 'preferentialTemplateVO.creativeTitleTemplateIsCovered',
                            width: 80,
                            customRender: (text, row) => {
                                let txt1 = <div class="box red">无</div>
                                let txt2 = <div class="box green">有</div>
                                return text == 1 ? txt2 : txt1
                            }
                        },
                        {
                            title: '创意标签模板',
                            dataIndex: ['preferentialTemplateVO', 'creativeLabelTemplateIsCovered'],
                            key: 'preferentialTemplateVO.creativeLabelTemplateIsCovered',
                            width: 80,
                            customRender: (text, row) => {
                                let txt1 = <div class="box red">无</div>
                                let txt2 = <div class="box green">有</div>
                                return text == 1 ? txt2 : txt1
                            }
                        },
                    ]
                },
                {
                    align: 'center',
                    title: '金融政策场景',
                    children: [
                        {
                            title: '橙子建站模板',
                            dataIndex: ['financeTemplateVO', 'landingPageTemplateIsCovered'],
                            key: 'financeTemplateVO.landingPageTemplateIsCovered',
                            width: 80,
                            customRender: (text, row) => {
                                let txt1 = <div class="box red">无</div>
                                let txt2 = <div class="box green">有</div>
                                return text == 1 ? txt2 : txt1
                            }
                        },
                        {
                            title: '巨量创意组件模板',
                            dataIndex: ['financeTemplateVO', 'creativeComponentTemplateIsCovered'],
                            key: 'financeTemplateVO.creativeComponentTemplateIsCovered',
                            width: 90,
                            customRender: (text, row) => {
                                let txt1 = <div class="box red">无</div>
                                let txt2 = <div class="box green">有</div>
                                return text == 1 ? txt2 : txt1
                            }
                        },
                        {
                            title: '创意标题模板',
                            dataIndex: ['financeTemplateVO', 'creativeTitleTemplateIsCovered'],
                            key: 'financeTemplateVO.creativeTitleTemplateIsCovered',
                            width: 80,
                            customRender: (text, row) => {
                                let txt1 = <div class="box red">无</div>
                                let txt2 = <div class="box green">有</div>
                                return text == 1 ? txt2 : txt1
                            }
                        },
                        {
                            title: '创意标签模板',
                            dataIndex: ['financeTemplateVO', 'creativeLabelTemplateIsCovered'],
                            key: 'financeTemplateVO.creativeLabelTemplateIsCovered',
                            width: 80,
                            customRender: (text, row) => {
                                let txt1 = <div class="box red">无</div>
                                let txt2 = <div class="box green">有</div>
                                return text == 1 ? txt2 : txt1
                            }
                        },
                    ]
                },
                {
                    align: 'center',
                    title: '节日活动场景',
                    children: [
                        {
                            title: '橙子建站模板',
                            dataIndex: ['activityTemplateVO', 'landingPageTemplateIsCovered'],
                            key: 'activityTemplateVO.landingPageTemplateIsCovered',
                            width: 80,
                            customRender: (text, row) => {
                                let txt1 = <div class="box red">无</div>
                                let txt2 = <div class="box green">有</div>
                                return text == 1 ? txt2 : txt1
                            }
                        },
                        {
                            title: '巨量创意组件模板',
                            dataIndex: ['activityTemplateVO', 'creativeComponentTemplateIsCovered'],
                            key: 'activityTemplateVO.creativeComponentTemplateIsCovered',
                            width: 90,
                            customRender: (text, row) => {
                                let txt1 = <div class="box red">无</div>
                                let txt2 = <div class="box green">有</div>
                                return text == 1 ? txt2 : txt1
                            }
                        },
                        {
                            title: '创意标题模板',
                            dataIndex: ['activityTemplateVO', 'creativeTitleTemplateIsCovered'],
                            key: 'activityTemplateVO.creativeTitleTemplateIsCovered',
                            width: 80,
                            customRender: (text, row) => {
                                let txt1 = <div class="box red">无</div>
                                let txt2 = <div class="box green">有</div>
                                return text == 1 ? txt2 : txt1
                            }
                        },
                        {
                            title: '创意标签模板',
                            dataIndex: ['activityTemplateVO', 'creativeLabelTemplateIsCovered'],
                            key: 'activityTemplateVO.creativeLabelTemplateIsCovered',
                            width: 80,
                            customRender: (text, row) => {
                                let txt1 = <div class="box red">无</div>
                                let txt2 = <div class="box green">有</div>
                                return text == 1 ? txt2 : txt1
                            }
                        },
                    ]
                },
            ],
            dataList: [],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0
            },
            principalList: [],
            stateList,
        }
    },
    created() {
        this.getTemplateCoverageSummaryList()
        this.getPrincipalList()
        this.getDataList()
    },
    methods: {
        getPrincipalList() {
            this.$api.base_api.getPrincipalList().then(res => {
                if(res.code == 200) {
                    this.principalList = res.data
                } else {
                    this.principalList = []
                    console.error(`获取主体失败，${res}`)
                }
            })
        },
        handleSearch() {
            this.pagination.current = 1
            this.getDataList()
        },
        handleReset() {
            this.form = {
                principalIdList: [],
                saleStatusList: []
            }
            this.pagination.current = 1
            this.pagination.pageSize = 10
            this.getDataList()
            this.seriesList = []
            this.getPrincipalSeriesList()
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
        getDataList(data = {}) {
            this.loading = true
            const _form = this.form
            let params = {
                principalIdStr: _form.principalIdList.toString() || undefined,
                saleStatusStr: _form.saleStatusList.toString() || undefined,
                page: this.pagination.current,
                size: this.pagination.pageSize,
            }
            this.$api.core.materialManage.getTemplateCoverStatisticsList(params).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    this.dataList = res.data.list || []
                    this.pagination.total = res.data.total || 0
                } else {
                    this.dataList = []
                    this.pagination.total = 0
                    console.error(`获取列表失败${res}`)
                }
            })
        },
        getTemplateCoverageSummaryList() {
            this.$api.core.materialManage.getTemplateCoverageSummaryList().then((res) => {
                if(res.code == 200) {
                    this.dataInfo = res.data || {}
                } else {
                    console.error(`获取汇总数据失败${res}`)
                }
            })
        },
    },
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
.content {
    padding: 40px 0;
    text-align: center;
    .rate {
        font-size: 50px;
    }
    .txt {
        margin-bottom: 10px;
        color: #ccc;
    }
}
.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.mt-20 {
    margin-top: 20px;
}
/deep/ .ant-table-row-cell-break-word {
    position: relative;
    .box {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 1px;
        bottom: 1px;
        color: rgba(0, 0, 0, 0.65);
        &.red {
            background-color: rgba(234,196,213,.65);
        }
        &.green {
            background-color: rgba(184,224,210,.65);
        }
    }
}
</style>
